import React, { useState, useEffect } from 'react'
import Grid from './components/Grid'
import './App.css'
import data from '../public/data/data.json'

function App() {
  const [data, setData] = useState<
    { imgSrc: string; title: string; description: string }[]
  >([])

  useEffect(() => {
    fetch('/path/to/data.json')
      .then((response) => response.json())
      .then((data) => setData(data))
  }, [])

  return (
    <div className="main">
      <div className="section">
        <div className="container">
          <h1>
            Pauline is a product-obsessed founder, full-stack software engineer,
            and digital designer.
          </h1>
          <p>Currently @ NYU + HackNY Fellow 2024</p>
        </div>
        <div className="container">
          <Grid rows={3} columns={3} data={data} />
        </div>
      </div>
    </div>
  )
}

export default App
