import React from 'react'

// interface GridChildProps {
//   imgSrc: string
//   title: string
//   description: string
// }

// {
// imgSrc,
// title,
// description,
// }

// : React.FC<GridChildProps>
const GridChild = () => {
  return (
    <div className="gridChild">
      <h3>Title</h3>
      <p>Description</p>
      {/* <img src={imgSrc} alt={title} />
      <h3>{title}</h3>
      <p>{description}</p> */}
    </div>
  )
}

export default GridChild
