import React from 'react'
import GridChild from './GridChild'

interface GridProps {
  rows: number
  columns: number
  data: { imgSrc: string; title: string; description: string }[]
}

const Grid: React.FC<GridProps> = ({ rows, columns }) => {
  //   const totalCells = rows * columns
  //   const cellsData = data.slice(0, totalCells)

  return (
    <div className="grid">
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <div key={rowIndex} className="row">
          {Array.from({ length: columns }).map((_, colIndex) => {
            // const cellIndex = rowIndex * columns + colIndex
            // const cellData = cellsData[cellIndex]
            return (
              <GridChild />
              //   <div key={colIndex} className="cell">
              //     {cellData && <GridChild {...cellData} />}
              //   </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default Grid
